<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="3" class="px-6">
        <Card
          :title="$t('download_documentation')"
          :vicon="'$help'"
          :activeted="true"
          :help="true"
        >
          <template>
            <v-btn
              v-if="$store.state.app.language == 'en'"
              href="/DocumentacioEnglish.pdf"
              download
              elevation="0"
              outlined
              style="height: 30px; width: 170px !important"
              ><v-icon small style="margin-right: 5px">$download</v-icon
              >{{ $t("download") }}</v-btn
            >
            <v-btn
              v-if="$store.state.app.language == 'es'"
              href="/Documentacio.pdf"
              download
              outlined
              elevation="0"
              style="height: 30px; width: 170px !important"
              ><v-icon small style="margin-right: 5px">$download</v-icon
              >{{ $t("download") }}</v-btn
            >
          </template>
        </Card>
      </v-col>
      <v-col cols="12" md="3" class="px-6">
        <Card :title="$t('Estadísticas')" :activeted="true" :help="true">
          <v-btn
            v-if="$store.state.app.language == 'es'"
            outlined
            @click="$router.push({ name: 'StatsView' })"
            elevation="0"
            style="height: 30px; width: 170px !important"
          >
            {{ $t("Ver") }}</v-btn
          >
        </Card>
      </v-col>
      <v-col cols="12" md="3" class="px-6">
        <Card :title="$t('Facturación')" :activeted="true" :help="true">
          <v-btn
            v-if="$store.state.app.language == 'es'"
            outlined
            @click="$router.push({ name: 'InvoicesList' })"
            elevation="0"
            style="height: 30px; width: 170px !important"
          >
            {{ $t("Ver") }}
          </v-btn>
        </Card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: "Help",
  components: {
    Card: () => import("@/components/user/settings/connection/Card"),
  },
};
</script>
